import React, { useRef, useEffect } from 'react';
import poster from './poster.png';
import poster2 from './poster2.png';
import music from './Music.mp3';
import './App.css';

function App() {
  const audioRef = useRef(null);
  const [flipped, setFlipped] = React.useState(false);

  const handle = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
    setFlipped(true);
  };

  useEffect(() => {
    const img1 = new Image();
    const img2 = new Image();
    img1.src = poster;
    img2.src = poster2;
  }, []);

  return (
    <div className="App">
      <header className="App-header">
      <div className={`flip-container ${flipped ? 'flipped' : ''}`} onClick={handle}>
      <div className="flipper">
      <img
          src={flipped ? poster : poster2}
          className="App-logo back"
          alt="logo"
        />
        </div>
        </div>
        <a
          className="App-text"
          href="https://www.google.com/maps/place/32.5193117,51.7099009"
          target="_blank"
          rel="noreferrer"
        >
          Location
        </a>
        <audio ref={audioRef} src={music} loop />
      </header>
    </div>
  );
}

export default App;
